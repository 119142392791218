/*! react-bootstrap-buttons v0.4.0 | (c) 2019 Cheton Wu <cheton@gmail.com> | MIT | https://github.com/cheton/react-bootstrap-buttons */
.bootstrap-buttons---btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -ms-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.bootstrap-buttons---btn.bootstrap-buttons---focus,
.bootstrap-buttons---btn:focus,
.bootstrap-buttons---btn.bootstrap-buttons---hover,
.bootstrap-buttons---btn:hover {
  text-decoration: none;
}
.bootstrap-buttons---btn.bootstrap-buttons---focus,
.bootstrap-buttons---btn:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0,0,255,0.25);
  box-shadow: 0 0 0 0.2rem rgba(0,0,255,0.25);
}
.bootstrap-buttons---btn.bootstrap-buttons---disabled,
.bootstrap-buttons---btn:disabled {
  opacity: 0.65;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
  filter: alpha(opacity=65);
}
.bootstrap-buttons---btn:not(:disabled):not(.bootstrap-buttons---disabled) {
  cursor: pointer;
}
.bootstrap-buttons---btn:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active,
.bootstrap-buttons---btn:not(:disabled):not(.bootstrap-buttons---disabled):active {
  background-image: none;
}
.bootstrap-buttons---btn + .bootstrap-buttons---btn {
  margin-left: 8px;
}
.bootstrap-buttons---btn-default {
  color: #111;
  background-color: #fff;
  border-color: #dee2e6;
}
.bootstrap-buttons---btn.bootstrap-buttons---btn-default > * {
  color: #111;
}
.bootstrap-buttons---btn-default.bootstrap-buttons---hover,
.bootstrap-buttons---btn-default:hover {
  color: #111;
  background-color: #d9d9d9;
  border-color: #abb5bf;
}
.bootstrap-buttons---btn-default.bootstrap-buttons---focus,
.bootstrap-buttons---btn-default:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(222,226,230,0.5);
  box-shadow: 0 0 0 0.2rem rgba(222,226,230,0.5);
  -webkit-box-shadow: 0 0 0 0.2rem rgba(222,226,230,0.5);
  box-shadow: 0 0 0 0.2rem rgba(222,226,230,0.5);
}
.bootstrap-buttons---btn-default.bootstrap-buttons---disabled,
.bootstrap-buttons---btn-default:disabled {
  color: #111;
  background-color: #fff;
  border-color: #dee2e6;
}
.bootstrap-buttons---btn-default:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active,
.bootstrap-buttons---btn-default:not(:disabled):not(.bootstrap-buttons---disabled):active {
  color: #111;
  background-color: #ccc;
  border-color: #9ea9b5;
}
.bootstrap-buttons---btn-default:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-default:not(:disabled):not(.bootstrap-buttons---disabled):active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-default:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active:focus,
.bootstrap-buttons---btn-default:not(:disabled):not(.bootstrap-buttons---disabled):active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(222,226,230,0.5);
  box-shadow: 0 0 0 0.2rem rgba(222,226,230,0.5);
}
.bootstrap-buttons---btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.bootstrap-buttons---btn.bootstrap-buttons---btn-primary > * {
  color: #fff;
}
.bootstrap-buttons---btn-primary.bootstrap-buttons---hover,
.bootstrap-buttons---btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.bootstrap-buttons---btn-primary.bootstrap-buttons---focus,
.bootstrap-buttons---btn-primary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.5);
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.5);
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.5);
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.5);
}
.bootstrap-buttons---btn-primary.bootstrap-buttons---disabled,
.bootstrap-buttons---btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.bootstrap-buttons---btn-primary:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active,
.bootstrap-buttons---btn-primary:not(:disabled):not(.bootstrap-buttons---disabled):active {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.bootstrap-buttons---btn-primary:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-primary:not(:disabled):not(.bootstrap-buttons---disabled):active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-primary:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active:focus,
.bootstrap-buttons---btn-primary:not(:disabled):not(.bootstrap-buttons---disabled):active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.5);
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.5);
}
.bootstrap-buttons---btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.bootstrap-buttons---btn.bootstrap-buttons---btn-secondary > * {
  color: #fff;
}
.bootstrap-buttons---btn-secondary.bootstrap-buttons---hover,
.bootstrap-buttons---btn-secondary:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
}
.bootstrap-buttons---btn-secondary.bootstrap-buttons---focus,
.bootstrap-buttons---btn-secondary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108,117,125,0.5);
  box-shadow: 0 0 0 0.2rem rgba(108,117,125,0.5);
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108,117,125,0.5);
  box-shadow: 0 0 0 0.2rem rgba(108,117,125,0.5);
}
.bootstrap-buttons---btn-secondary.bootstrap-buttons---disabled,
.bootstrap-buttons---btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.bootstrap-buttons---btn-secondary:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active,
.bootstrap-buttons---btn-secondary:not(:disabled):not(.bootstrap-buttons---disabled):active {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
}
.bootstrap-buttons---btn-secondary:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-secondary:not(:disabled):not(.bootstrap-buttons---disabled):active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-secondary:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active:focus,
.bootstrap-buttons---btn-secondary:not(:disabled):not(.bootstrap-buttons---disabled):active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108,117,125,0.5);
  box-shadow: 0 0 0 0.2rem rgba(108,117,125,0.5);
}
.bootstrap-buttons---btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.bootstrap-buttons---btn.bootstrap-buttons---btn-danger > * {
  color: #fff;
}
.bootstrap-buttons---btn-danger.bootstrap-buttons---hover,
.bootstrap-buttons---btn-danger:hover {
  color: #fff;
  background-color: #c62232;
  border-color: #ba202f;
}
.bootstrap-buttons---btn-danger.bootstrap-buttons---focus,
.bootstrap-buttons---btn-danger:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220,53,69,0.5);
  box-shadow: 0 0 0 0.2rem rgba(220,53,69,0.5);
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220,53,69,0.5);
  box-shadow: 0 0 0 0.2rem rgba(220,53,69,0.5);
}
.bootstrap-buttons---btn-danger.bootstrap-buttons---disabled,
.bootstrap-buttons---btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.bootstrap-buttons---btn-danger:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active,
.bootstrap-buttons---btn-danger:not(:disabled):not(.bootstrap-buttons---disabled):active {
  color: #fff;
  background-color: #ba202f;
  border-color: #af1e2c;
}
.bootstrap-buttons---btn-danger:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-danger:not(:disabled):not(.bootstrap-buttons---disabled):active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-danger:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active:focus,
.bootstrap-buttons---btn-danger:not(:disabled):not(.bootstrap-buttons---disabled):active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220,53,69,0.5);
  box-shadow: 0 0 0 0.2rem rgba(220,53,69,0.5);
}
.bootstrap-buttons---btn-warning {
  color: #111;
  background-color: #ffc107;
  border-color: #ffc107;
}
.bootstrap-buttons---btn.bootstrap-buttons---btn-warning > * {
  color: #111;
}
.bootstrap-buttons---btn-warning.bootstrap-buttons---hover,
.bootstrap-buttons---btn-warning:hover {
  color: #111;
  background-color: #dfa700;
  border-color: #d29d00;
}
.bootstrap-buttons---btn-warning.bootstrap-buttons---focus,
.bootstrap-buttons---btn-warning:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255,193,7,0.5);
  box-shadow: 0 0 0 0.2rem rgba(255,193,7,0.5);
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255,193,7,0.5);
  box-shadow: 0 0 0 0.2rem rgba(255,193,7,0.5);
}
.bootstrap-buttons---btn-warning.bootstrap-buttons---disabled,
.bootstrap-buttons---btn-warning:disabled {
  color: #111;
  background-color: #ffc107;
  border-color: #ffc107;
}
.bootstrap-buttons---btn-warning:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active,
.bootstrap-buttons---btn-warning:not(:disabled):not(.bootstrap-buttons---disabled):active {
  color: #111;
  background-color: #d29d00;
  border-color: #c49300;
}
.bootstrap-buttons---btn-warning:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-warning:not(:disabled):not(.bootstrap-buttons---disabled):active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-warning:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active:focus,
.bootstrap-buttons---btn-warning:not(:disabled):not(.bootstrap-buttons---disabled):active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255,193,7,0.5);
  box-shadow: 0 0 0 0.2rem rgba(255,193,7,0.5);
}
.bootstrap-buttons---btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.bootstrap-buttons---btn.bootstrap-buttons---btn-info > * {
  color: #fff;
}
.bootstrap-buttons---btn-info.bootstrap-buttons---hover,
.bootstrap-buttons---btn-info:hover {
  color: #fff;
  background-color: #148a9c;
  border-color: #128293;
}
.bootstrap-buttons---btn-info.bootstrap-buttons---focus,
.bootstrap-buttons---btn-info:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23,162,184,0.5);
  box-shadow: 0 0 0 0.2rem rgba(23,162,184,0.5);
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23,162,184,0.5);
  box-shadow: 0 0 0 0.2rem rgba(23,162,184,0.5);
}
.bootstrap-buttons---btn-info.bootstrap-buttons---disabled,
.bootstrap-buttons---btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.bootstrap-buttons---btn-info:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active,
.bootstrap-buttons---btn-info:not(:disabled):not(.bootstrap-buttons---disabled):active {
  color: #fff;
  background-color: #128293;
  border-color: #11798a;
}
.bootstrap-buttons---btn-info:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-info:not(:disabled):not(.bootstrap-buttons---disabled):active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-info:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active:focus,
.bootstrap-buttons---btn-info:not(:disabled):not(.bootstrap-buttons---disabled):active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23,162,184,0.5);
  box-shadow: 0 0 0 0.2rem rgba(23,162,184,0.5);
}
.bootstrap-buttons---btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.bootstrap-buttons---btn.bootstrap-buttons---btn-success > * {
  color: #fff;
}
.bootstrap-buttons---btn-success.bootstrap-buttons---hover,
.bootstrap-buttons---btn-success:hover {
  color: #fff;
  background-color: #228e3b;
  border-color: #208637;
}
.bootstrap-buttons---btn-success.bootstrap-buttons---focus,
.bootstrap-buttons---btn-success:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40,167,69,0.5);
  box-shadow: 0 0 0 0.2rem rgba(40,167,69,0.5);
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40,167,69,0.5);
  box-shadow: 0 0 0 0.2rem rgba(40,167,69,0.5);
}
.bootstrap-buttons---btn-success.bootstrap-buttons---disabled,
.bootstrap-buttons---btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.bootstrap-buttons---btn-success:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active,
.bootstrap-buttons---btn-success:not(:disabled):not(.bootstrap-buttons---disabled):active {
  color: #fff;
  background-color: #208637;
  border-color: #1e7d34;
}
.bootstrap-buttons---btn-success:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-success:not(:disabled):not(.bootstrap-buttons---disabled):active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-success:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active:focus,
.bootstrap-buttons---btn-success:not(:disabled):not(.bootstrap-buttons---disabled):active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40,167,69,0.5);
  box-shadow: 0 0 0 0.2rem rgba(40,167,69,0.5);
}
.bootstrap-buttons---btn-light {
  color: #111;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.bootstrap-buttons---btn.bootstrap-buttons---btn-light > * {
  color: #111;
}
.bootstrap-buttons---btn-light.bootstrap-buttons---hover,
.bootstrap-buttons---btn-light:hover {
  color: #111;
  background-color: #ccd4db;
  border-color: #bec7d0;
}
.bootstrap-buttons---btn-light.bootstrap-buttons---focus,
.bootstrap-buttons---btn-light:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248,249,250,0.5);
  box-shadow: 0 0 0 0.2rem rgba(248,249,250,0.5);
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248,249,250,0.5);
  box-shadow: 0 0 0 0.2rem rgba(248,249,250,0.5);
}
.bootstrap-buttons---btn-light.bootstrap-buttons---disabled,
.bootstrap-buttons---btn-light:disabled {
  color: #111;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.bootstrap-buttons---btn-light:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active,
.bootstrap-buttons---btn-light:not(:disabled):not(.bootstrap-buttons---disabled):active {
  color: #111;
  background-color: #bec7d0;
  border-color: #afbbc6;
}
.bootstrap-buttons---btn-light:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-light:not(:disabled):not(.bootstrap-buttons---disabled):active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-light:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active:focus,
.bootstrap-buttons---btn-light:not(:disabled):not(.bootstrap-buttons---disabled):active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248,249,250,0.5);
  box-shadow: 0 0 0 0.2rem rgba(248,249,250,0.5);
}
.bootstrap-buttons---btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.bootstrap-buttons---btn.bootstrap-buttons---btn-dark > * {
  color: #fff;
}
.bootstrap-buttons---btn-dark.bootstrap-buttons---hover,
.bootstrap-buttons---btn-dark:hover {
  color: #fff;
  background-color: #2c3136;
  border-color: #2a2e33;
}
.bootstrap-buttons---btn-dark.bootstrap-buttons---focus,
.bootstrap-buttons---btn-dark:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52,58,64,0.5);
  box-shadow: 0 0 0 0.2rem rgba(52,58,64,0.5);
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52,58,64,0.5);
  box-shadow: 0 0 0 0.2rem rgba(52,58,64,0.5);
}
.bootstrap-buttons---btn-dark.bootstrap-buttons---disabled,
.bootstrap-buttons---btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.bootstrap-buttons---btn-dark:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active,
.bootstrap-buttons---btn-dark:not(:disabled):not(.bootstrap-buttons---disabled):active {
  color: #fff;
  background-color: #2a2e33;
  border-color: #272b30;
}
.bootstrap-buttons---btn-dark:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-dark:not(:disabled):not(.bootstrap-buttons---disabled):active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-dark:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active:focus,
.bootstrap-buttons---btn-dark:not(:disabled):not(.bootstrap-buttons---disabled):active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52,58,64,0.5);
  box-shadow: 0 0 0 0.2rem rgba(52,58,64,0.5);
}
.bootstrap-buttons---btn-outline-default {
  color: #dee2e6;
  background-color: transparent;
  background-image: none;
  border-color: #dee2e6;
}
.bootstrap-buttons---btn.bootstrap-buttons---btn-outline-default > * {
  color: #111;
}
.bootstrap-buttons---btn-outline-default.bootstrap-buttons---hover,
.bootstrap-buttons---btn-outline-default:hover {
  color: #111;
  background-color: #dee2e6;
  border-color: #dee2e6;
}
.bootstrap-buttons---btn-outline-default.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-default:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(222,226,230,0.5);
  box-shadow: 0 0 0 0.2rem rgba(222,226,230,0.5);
}
.bootstrap-buttons---btn-outline-default.bootstrap-buttons---disabled,
.bootstrap-buttons---btn-outline-default:disabled {
  color: #dee2e6;
  background-color: transparent;
}
.bootstrap-buttons---btn-outline-default:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active,
.bootstrap-buttons---btn-outline-default:not(:disabled):not(.bootstrap-buttons---disabled):active {
  color: #111;
  background-color: #dee2e6;
  border-color: #dee2e6;
}
.bootstrap-buttons---btn-outline-default:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-default:not(:disabled):not(.bootstrap-buttons---disabled):active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-default:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active:focus,
.bootstrap-buttons---btn-outline-default:not(:disabled):not(.bootstrap-buttons---disabled):active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(222,226,230,0.5);
  box-shadow: 0 0 0 0.2rem rgba(222,226,230,0.5);
}
.bootstrap-buttons---btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}
.bootstrap-buttons---btn.bootstrap-buttons---btn-outline-primary > * {
  color: #fff;
}
.bootstrap-buttons---btn-outline-primary.bootstrap-buttons---hover,
.bootstrap-buttons---btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.bootstrap-buttons---btn-outline-primary.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-primary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.5);
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.5);
}
.bootstrap-buttons---btn-outline-primary.bootstrap-buttons---disabled,
.bootstrap-buttons---btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.bootstrap-buttons---btn-outline-primary:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active,
.bootstrap-buttons---btn-outline-primary:not(:disabled):not(.bootstrap-buttons---disabled):active {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.bootstrap-buttons---btn-outline-primary:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-primary:not(:disabled):not(.bootstrap-buttons---disabled):active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-primary:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active:focus,
.bootstrap-buttons---btn-outline-primary:not(:disabled):not(.bootstrap-buttons---disabled):active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.5);
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.5);
}
.bootstrap-buttons---btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}
.bootstrap-buttons---btn.bootstrap-buttons---btn-outline-secondary > * {
  color: #fff;
}
.bootstrap-buttons---btn-outline-secondary.bootstrap-buttons---hover,
.bootstrap-buttons---btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.bootstrap-buttons---btn-outline-secondary.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-secondary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108,117,125,0.5);
  box-shadow: 0 0 0 0.2rem rgba(108,117,125,0.5);
}
.bootstrap-buttons---btn-outline-secondary.bootstrap-buttons---disabled,
.bootstrap-buttons---btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.bootstrap-buttons---btn-outline-secondary:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active,
.bootstrap-buttons---btn-outline-secondary:not(:disabled):not(.bootstrap-buttons---disabled):active {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.bootstrap-buttons---btn-outline-secondary:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-secondary:not(:disabled):not(.bootstrap-buttons---disabled):active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-secondary:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active:focus,
.bootstrap-buttons---btn-outline-secondary:not(:disabled):not(.bootstrap-buttons---disabled):active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108,117,125,0.5);
  box-shadow: 0 0 0 0.2rem rgba(108,117,125,0.5);
}
.bootstrap-buttons---btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}
.bootstrap-buttons---btn.bootstrap-buttons---btn-outline-danger > * {
  color: #fff;
}
.bootstrap-buttons---btn-outline-danger.bootstrap-buttons---hover,
.bootstrap-buttons---btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.bootstrap-buttons---btn-outline-danger.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-danger:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220,53,69,0.5);
  box-shadow: 0 0 0 0.2rem rgba(220,53,69,0.5);
}
.bootstrap-buttons---btn-outline-danger.bootstrap-buttons---disabled,
.bootstrap-buttons---btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.bootstrap-buttons---btn-outline-danger:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active,
.bootstrap-buttons---btn-outline-danger:not(:disabled):not(.bootstrap-buttons---disabled):active {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.bootstrap-buttons---btn-outline-danger:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-danger:not(:disabled):not(.bootstrap-buttons---disabled):active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-danger:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active:focus,
.bootstrap-buttons---btn-outline-danger:not(:disabled):not(.bootstrap-buttons---disabled):active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220,53,69,0.5);
  box-shadow: 0 0 0 0.2rem rgba(220,53,69,0.5);
}
.bootstrap-buttons---btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}
.bootstrap-buttons---btn.bootstrap-buttons---btn-outline-warning > * {
  color: #111;
}
.bootstrap-buttons---btn-outline-warning.bootstrap-buttons---hover,
.bootstrap-buttons---btn-outline-warning:hover {
  color: #111;
  background-color: #ffc107;
  border-color: #ffc107;
}
.bootstrap-buttons---btn-outline-warning.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-warning:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255,193,7,0.5);
  box-shadow: 0 0 0 0.2rem rgba(255,193,7,0.5);
}
.bootstrap-buttons---btn-outline-warning.bootstrap-buttons---disabled,
.bootstrap-buttons---btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.bootstrap-buttons---btn-outline-warning:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active,
.bootstrap-buttons---btn-outline-warning:not(:disabled):not(.bootstrap-buttons---disabled):active {
  color: #111;
  background-color: #ffc107;
  border-color: #ffc107;
}
.bootstrap-buttons---btn-outline-warning:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-warning:not(:disabled):not(.bootstrap-buttons---disabled):active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-warning:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active:focus,
.bootstrap-buttons---btn-outline-warning:not(:disabled):not(.bootstrap-buttons---disabled):active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255,193,7,0.5);
  box-shadow: 0 0 0 0.2rem rgba(255,193,7,0.5);
}
.bootstrap-buttons---btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}
.bootstrap-buttons---btn.bootstrap-buttons---btn-outline-info > * {
  color: #fff;
}
.bootstrap-buttons---btn-outline-info.bootstrap-buttons---hover,
.bootstrap-buttons---btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.bootstrap-buttons---btn-outline-info.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-info:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23,162,184,0.5);
  box-shadow: 0 0 0 0.2rem rgba(23,162,184,0.5);
}
.bootstrap-buttons---btn-outline-info.bootstrap-buttons---disabled,
.bootstrap-buttons---btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.bootstrap-buttons---btn-outline-info:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active,
.bootstrap-buttons---btn-outline-info:not(:disabled):not(.bootstrap-buttons---disabled):active {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.bootstrap-buttons---btn-outline-info:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-info:not(:disabled):not(.bootstrap-buttons---disabled):active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-info:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active:focus,
.bootstrap-buttons---btn-outline-info:not(:disabled):not(.bootstrap-buttons---disabled):active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23,162,184,0.5);
  box-shadow: 0 0 0 0.2rem rgba(23,162,184,0.5);
}
.bootstrap-buttons---btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}
.bootstrap-buttons---btn.bootstrap-buttons---btn-outline-success > * {
  color: #fff;
}
.bootstrap-buttons---btn-outline-success.bootstrap-buttons---hover,
.bootstrap-buttons---btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.bootstrap-buttons---btn-outline-success.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-success:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40,167,69,0.5);
  box-shadow: 0 0 0 0.2rem rgba(40,167,69,0.5);
}
.bootstrap-buttons---btn-outline-success.bootstrap-buttons---disabled,
.bootstrap-buttons---btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.bootstrap-buttons---btn-outline-success:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active,
.bootstrap-buttons---btn-outline-success:not(:disabled):not(.bootstrap-buttons---disabled):active {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.bootstrap-buttons---btn-outline-success:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-success:not(:disabled):not(.bootstrap-buttons---disabled):active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-success:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active:focus,
.bootstrap-buttons---btn-outline-success:not(:disabled):not(.bootstrap-buttons---disabled):active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40,167,69,0.5);
  box-shadow: 0 0 0 0.2rem rgba(40,167,69,0.5);
}
.bootstrap-buttons---btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}
.bootstrap-buttons---btn.bootstrap-buttons---btn-outline-light > * {
  color: #111;
}
.bootstrap-buttons---btn-outline-light.bootstrap-buttons---hover,
.bootstrap-buttons---btn-outline-light:hover {
  color: #111;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.bootstrap-buttons---btn-outline-light.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-light:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248,249,250,0.5);
  box-shadow: 0 0 0 0.2rem rgba(248,249,250,0.5);
}
.bootstrap-buttons---btn-outline-light.bootstrap-buttons---disabled,
.bootstrap-buttons---btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.bootstrap-buttons---btn-outline-light:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active,
.bootstrap-buttons---btn-outline-light:not(:disabled):not(.bootstrap-buttons---disabled):active {
  color: #111;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.bootstrap-buttons---btn-outline-light:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-light:not(:disabled):not(.bootstrap-buttons---disabled):active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-light:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active:focus,
.bootstrap-buttons---btn-outline-light:not(:disabled):not(.bootstrap-buttons---disabled):active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248,249,250,0.5);
  box-shadow: 0 0 0 0.2rem rgba(248,249,250,0.5);
}
.bootstrap-buttons---btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}
.bootstrap-buttons---btn.bootstrap-buttons---btn-outline-dark > * {
  color: #fff;
}
.bootstrap-buttons---btn-outline-dark.bootstrap-buttons---hover,
.bootstrap-buttons---btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.bootstrap-buttons---btn-outline-dark.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-dark:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52,58,64,0.5);
  box-shadow: 0 0 0 0.2rem rgba(52,58,64,0.5);
}
.bootstrap-buttons---btn-outline-dark.bootstrap-buttons---disabled,
.bootstrap-buttons---btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.bootstrap-buttons---btn-outline-dark:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active,
.bootstrap-buttons---btn-outline-dark:not(:disabled):not(.bootstrap-buttons---disabled):active {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.bootstrap-buttons---btn-outline-dark:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-dark:not(:disabled):not(.bootstrap-buttons---disabled):active.bootstrap-buttons---focus,
.bootstrap-buttons---btn-outline-dark:not(:disabled):not(.bootstrap-buttons---disabled).bootstrap-buttons---active:focus,
.bootstrap-buttons---btn-outline-dark:not(:disabled):not(.bootstrap-buttons---disabled):active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52,58,64,0.5);
  box-shadow: 0 0 0 0.2rem rgba(52,58,64,0.5);
}
.bootstrap-buttons---btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}
.bootstrap-buttons---btn-link:focus,
.bootstrap-buttons---btn-link.bootstrap-buttons---focus {
  text-decoration: underline;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.bootstrap-buttons---btn-link:disabled,
.bootstrap-buttons---btn-link.bootstrap-buttons---disabled {
  color: #6c757d;
}
.bootstrap-buttons---btn-xs,
.bootstrap-buttons---btn-group-xs > .bootstrap-buttons---btn {
  padding: 0.125rem 0.25rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.15rem;
}
.bootstrap-buttons---btn-sm,
.bootstrap-buttons---btn-group-sm > .bootstrap-buttons---btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.bootstrap-buttons---btn-md,
.bootstrap-buttons---btn-group-md > .bootstrap-buttons---btn {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.bootstrap-buttons---btn-lg,
.bootstrap-buttons---btn-group-lg > .bootstrap-buttons---btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.bootstrap-buttons---btn-block {
  display: block;
  width: 100%;
}
.bootstrap-buttons---btn-block + .bootstrap-buttons---btn-block {
  margin-top: 0.5rem;
}
input[type="submit"].bootstrap-buttons---btn-block,
input[type="reset"].bootstrap-buttons---btn-block,
input[type="button"].bootstrap-buttons---btn-block {
  width: 100%;
}
.bootstrap-buttons---btn-group,
.bootstrap-buttons---btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  vertical-align: middle;
}
.bootstrap-buttons---btn-group > .bootstrap-buttons---btn,
.bootstrap-buttons---btn-group-vertical > .bootstrap-buttons---btn {
  position: relative;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.bootstrap-buttons---btn-group > .bootstrap-buttons---btn.bootstrap-buttons---hover,
.bootstrap-buttons---btn-group-vertical > .bootstrap-buttons---btn.bootstrap-buttons---hover,
.bootstrap-buttons---btn-group > .bootstrap-buttons---btn:hover,
.bootstrap-buttons---btn-group-vertical > .bootstrap-buttons---btn:hover {
  z-index: 1;
}
.bootstrap-buttons---btn-group > .bootstrap-buttons---btn.bootstrap-buttons---focus,
.bootstrap-buttons---btn-group-vertical > .bootstrap-buttons---btn.bootstrap-buttons---focus,
.bootstrap-buttons---btn-group > .bootstrap-buttons---btn:focus,
.bootstrap-buttons---btn-group-vertical > .bootstrap-buttons---btn:focus,
.bootstrap-buttons---btn-group > .bootstrap-buttons---btn.bootstrap-buttons---active,
.bootstrap-buttons---btn-group-vertical > .bootstrap-buttons---btn.bootstrap-buttons---active,
.bootstrap-buttons---btn-group > .bootstrap-buttons---btn:active,
.bootstrap-buttons---btn-group-vertical > .bootstrap-buttons---btn:active {
  z-index: 1;
}
.bootstrap-buttons---btn-group .bootstrap-buttons---btn + .bootstrap-buttons---btn,
.bootstrap-buttons---btn-group-vertical .bootstrap-buttons---btn + .bootstrap-buttons---btn,
.bootstrap-buttons---btn-group .bootstrap-buttons---btn + .bootstrap-buttons---btn-group,
.bootstrap-buttons---btn-group-vertical .bootstrap-buttons---btn + .bootstrap-buttons---btn-group,
.bootstrap-buttons---btn-group .bootstrap-buttons---btn-group + .bootstrap-buttons---btn,
.bootstrap-buttons---btn-group-vertical .bootstrap-buttons---btn-group + .bootstrap-buttons---btn,
.bootstrap-buttons---btn-group .bootstrap-buttons---btn-group + .bootstrap-buttons---btn-group,
.bootstrap-buttons---btn-group-vertical .bootstrap-buttons---btn-group + .bootstrap-buttons---btn-group {
  margin-left: -1px;
}
.bootstrap-buttons---btn-group > .bootstrap-buttons---btn:first-child {
  margin-left: 0;
}
.bootstrap-buttons---btn-group > .bootstrap-buttons---btn:not(:last-child),
.bootstrap-buttons---btn-group > .bootstrap-buttons---btn-group:not(:last-child) > .bootstrap-buttons---btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.bootstrap-buttons---btn-group > .bootstrap-buttons---btn:not(:first-child),
.bootstrap-buttons---btn-group > .bootstrap-buttons---btn-group:not(:first-child) > .bootstrap-buttons---btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.bootstrap-buttons---btn-group-vertical {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.bootstrap-buttons---btn-group-vertical .bootstrap-buttons---btn,
.bootstrap-buttons---btn-group-vertical .bootstrap-buttons---btn-group {
  width: 100%;
}
.bootstrap-buttons---btn-group-vertical > .bootstrap-buttons---btn + .bootstrap-buttons---btn,
.bootstrap-buttons---btn-group-vertical > .bootstrap-buttons---btn + .bootstrap-buttons---btn-group,
.bootstrap-buttons---btn-group-vertical > .bootstrap-buttons---btn-group + .bootstrap-buttons---btn,
.bootstrap-buttons---btn-group-vertical > .bootstrap-buttons---btn-group + .bootstrap-buttons---btn-group {
  margin-top: -1px;
  margin-left: 0;
}
.bootstrap-buttons---btn-group-vertical > .bootstrap-buttons---btn:not(:last-child),
.bootstrap-buttons---btn-group-vertical > .bootstrap-buttons---btn-group:not(:last-child) > .bootstrap-buttons---btn {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.bootstrap-buttons---btn-group-vertical > .bootstrap-buttons---btn:not(:first-child),
.bootstrap-buttons---btn-group-vertical > .bootstrap-buttons---btn-group:not(:first-child) > .bootstrap-buttons---btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.bootstrap-buttons---btn-toolbar {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -o-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}


/*# sourceMappingURL=react-bootstrap-buttons.css.map*/